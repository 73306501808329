import t from '@jetshop/intl'
import React, { useState, useEffect, useMemo } from 'react'
import { Query } from 'react-apollo'
import { styled } from 'linaria/react'
import { fuzzySearch } from '@jetshop/ui/fuzzySearch'
import { Above } from '@jetshop/ui/Breakpoints'
import { distance } from '@jetshop/core/helpers/distance'
import { ReactComponent as SearchIcon } from '../../svg/Search.svg'
import MaxWidth from '../Layout/MaxWidth'
import LoadingPage from '../LoadingPage'
import storeLocatorQuery from './StoreLocator.gql'
import StoreSearch from './StoreSearch'
import StoreList from './StoreList'
import StoreMap from './StoreMap'
import { useLocationState } from './useLocationState'
import Head from '@jetshop/core/components/Head'
import { Intl } from '@jetshop/intl'
import { theme } from '../Theme'
import { StyledBreadcrumbs } from '../ui/StyledBreadcrumbs'

const Flex = styled('div')`
  display: flex;

  h1 {
    margin-bottom: 0.5em;
  }

  > div {
    &:first-child {
      flex: 1 20%;
    }
    flex: 1;
  }

  ${theme.below.lg} {
    flex-direction: column;
  }
`

const StoreLocatorWrapper = styled(MaxWidth)`
  max-width: 900px;
  padding: 4rem 2rem;

  ${theme.below.lg} {
    padding: 0;
  }
  &.get-in-store {
    padding: 0;
  }
`

const StoreLocatorContent = styled(Flex)`
  height: 60vh;

  ${theme.below.lg} {
    height: unset;
  }
`

const SearchAndListContainer = styled('div')`
  height: 100%;
  margin-left: 25px;

  h1 {
    font-family: ${theme.fonts.title};
    font-size: 30px;
    font-weight: normal;
    text-align: center;
  }
`

const GetInStoreWrapper = styled('div')`
  position: relative;
  & > p {
    max-width: 80%;
    margin: 0 auto 1rem;
    text-align: center;
  }

  h2 {
    text-align: center;
    font-family: ${theme.fonts.title};
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.infoTitle};
    line-height: 35px;
    margin-bottom: 20px;
  }

  .in-store-search-wrapper {
    position: relative;

    .search-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      z-index: 1;
      pointer-events: none;
    }

    input {
      background: rgba(242, 240, 235, 0.4);
      border: 1px solid #e8e5de;
      border-radius: 30px;
      height: 43px;
      line-height: 43px;
      padding: 0 10px 0 50px;
      font-size: 12px;
      width: 100%;
      :focus {
        outline: none;
      }
    }

    :after {
      z-index: 9;
      content: '';
      height: 15px;
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -15px;
    }
  }

  .user-location-container {
    margin-top: 1rem;
  }
`

export const LocationStateContext = React.createContext(null)

const StoreLocator = ({ getInStoreCallback, title, canExpand, product }) => {
  const locationState = useLocationState()
  const [search, setSearch] = useState('')
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null
  })
  const [closestStore, setClosestStore] = useState(null)
  const [distances, setDistances] = useState({})

  const fetchUrl = `https://hc-apim-prod.azure-api.net/inventory-visibility/onhand?productid=${product?.articleNumber}`
  const options = useMemo(
    () => ({
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': '615d0246860a47a1b3998ebcf2481050'
      }
    }),
    []
  )

  const [externalStoreApiData, setExternalStoreApiData] = useState()
  const [info, setInfo] = useState()

  useEffect(() => {
    if (!externalStoreApiData) {
      fetch(fetchUrl, options)
        .then(response => {
          const statusObj = {
            status: response?.status ?? null,
            statusText: response?.statusText ?? null
          }
          setInfo(statusObj)

          return response?.json()
        })
        .then(data => {
          setExternalStoreApiData(data)
        })
    }
  }, [fetchUrl, options])

  const genDistances = stores => {
    if (userLocation.latitude == null) return

    let distances = {}
    let closestStore = {}
    stores
      .filter(store => store.coordinates)
      .forEach(store => {
        const thisDistance = distance(userLocation, store.coordinates)

        distances[store.id] = thisDistance

        // If we haven't got a store in the closestStore obj yet, set it to this one
        if (!closestStore.distance) {
          closestStore = {
            ...store,
            distance: thisDistance
          }
          return
        }

        // If this store is closer than the one currently stored in closestStore,
        // override it
        if (thisDistance < closestStore.distance) {
          closestStore = { ...store, distance: thisDistance }
        }
      })

    setDistances(distances)
    setClosestStore(closestStore)
  }

  const hiddenStores = ['Sentrallager - Jessheim']

  return (
    <>
      <Intl>{t => <Head data={{ title: t('Find store') }} />}</Intl>
      {!getInStoreCallback && <StyledBreadcrumbs breadcrumbText={'Butikker'} />}
      <StoreLocatorWrapper className={getInStoreCallback && 'get-in-store'}>
        <StoreLocatorContent>
          <Query query={storeLocatorQuery}>
            {({ data, loading }) => {
              if (
                loading ||
                (getInStoreCallback ? !info && !externalStoreApiData : false)
              )
                return <LoadingPage />

              if (userLocation.latitude && !closestStore)
                genDistances(data.stores)

              const modStores = getInStoreCallback
                ? data?.stores
                    ?.filter(store => !hiddenStores.includes(store.name))
                    .map(store => {
                      const matchedStoreData = externalStoreApiData?.find(
                        externalStore =>
                          externalStore?.dimensions?.locationid ===
                          store?.externalId
                      )
                      const buyable =
                        matchedStoreData?.quantities?.iv?.onhand > 0
                      const status = buyable ? 'inStock' : 'outOfStock'
                      const text = buyable ? 'På lager' : 'Ikke på lager'
                      const statusObj = { status, text }

                      return { ...store, stockstatus: statusObj }
                    })
                : data?.stores

              const stores =
                search?.length === 0
                  ? modStores
                  : fuzzySearch({
                      data: modStores,
                      keys: ['name', 'address1'],
                      searchTerm: search
                    })

              const storeSearchProps = { search, setSearch }
              const storeListProps = {
                stores,
                userLocation,
                setUserLocation,
                distances
              }
              const storeMapProps = {
                stores,
                userLocation,
                closestStore,
                distances
              }

              return (
                <LocationStateContext.Provider value={locationState}>
                  {getInStoreCallback ? (
                    <GetInStoreWrapper>
                      <h2>{title}</h2>
                      <p>
                        Du mottar en SMS når varen er klar for henting. Du
                        betaler ingen ekstra kostnader for frakt på
                        Kjøp&Hent-produkter.
                      </p>
                      <div className="in-store-search-wrapper">
                        <input
                          className={'store-search'}
                          placeholder="Søk etter butikk"
                          type={'search'}
                          id="search-field"
                          value={storeSearchProps.search}
                          onChange={e => {
                            storeSearchProps.setSearch(e.target.value)
                          }}
                        />
                        <SearchIcon className="search-icon" />
                      </div>
                      <StoreList
                        canExpand={canExpand}
                        {...storeListProps}
                        getInStoreCallback={e => getInStoreCallback(e)}
                      />
                    </GetInStoreWrapper>
                  ) : (
                    <Above breakpoint="lg">
                      {matches =>
                        matches ? (
                          <>
                            <StoreMap {...storeMapProps} />
                            <SearchAndListContainer>
                              <h1>{t('Find store')}</h1>
                              <StoreSearch {...storeSearchProps} />
                              <StoreList {...storeListProps} />
                            </SearchAndListContainer>
                          </>
                        ) : (
                          <>
                            <StoreSearch {...storeSearchProps} />
                            <StoreMap {...storeMapProps} />
                            <StoreList {...storeListProps} />
                          </>
                        )
                      }
                    </Above>
                  )}
                </LocationStateContext.Provider>
              )
            }}
          </Query>
        </StoreLocatorContent>
      </StoreLocatorWrapper>
    </>
  )
}

export default StoreLocator
