import React from 'react';
import ContentPageLink from '@jetshop/ui/ContentPageLink';

function FooterLinks({ links }) {
  return (
    <div>
      {links && (
        <div>
          <h3>{links?.name}</h3>
          <ul>
            {links?.subPages?.map(subPage => (
              <li key={subPage.id}>
                <ContentPageLink page={subPage}>
                  {subPage?.name}
                </ContentPageLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default FooterLinks;
